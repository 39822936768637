.payload {
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
}
.Home {
  padding: 1rem;

    .filter-section {
      // .ui.fluid.dropdown,
      .MuiPaper-root {
        width: 15vw;
        // text-align: center;
        div:first-of-type {
          width: 100%;
          margin: 0;
        }
      }
      button {
        button {
          width: auto;
          margin: 0 2px 0 0;
        }
      }
      .step-details {
        padding: 2rem 2rem 4rem 2rem !important;
      }
  
      .empty-table {
        position: relative;
        margin: 20vh 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .striped-table {
        thead {
          th:nth-of-type(1) {
            width: 37.5%;
          }
          th:nth-of-type(4) {
            width: 12.5%;
          }
        }
        tbody {
          .status {
            font-size: 14px;
            padding: 1px 16px 0px 13px !important;
            width: fit-content;
            border-radius: 50px;
            color: white;

            &.completed {
              background-color: $blue;
            }
            &.active {
              background-color: green;
            }
            &.stopped {
              background-color: red;
            }
            &.transacted {
              background-color: gray;
            }
            &.retired {
              background-color: $forestgreen;
            }
          }
        }
      }
    }
}
