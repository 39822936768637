.error-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 15vh 0;
  color: $forestgreen;
  font-size: 1.5rem;
  svg {
    width: 100%;
    height: 75px;
    margin-bottom: 1rem;
  }
  a:hover {
    text-decoration: underline;
  }
}
