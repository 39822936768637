.header {
  background-color: $oceangreen;
  height: 10vh;
  max-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-s $spacing-m;

  .logo {
    margin: $spacing-s 0;
    max-width: 150px;
  }

  .user_menu {
    color: $forestgreen;
    font-size: 20px;
    display: flex;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $seafoamgreen;
      font-size: 14px;
      margin: 0 10px 0 0;
    }

    // .ui.dropdown {
    //   background-color: $seafoamgreen;
    //   border-radius: 300px;
    //   width: 40px;
    //   height: 40px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   user-select: none;
    //   text-transform: uppercase;
    //   .initial {
    //     color: $forestgreen;
    //     text-align: center;
    //     margin: 0;
    //     font-size: 20px;
    //   }
    //   .menu {
    //     left: -270px;
    //     color: white;
    //     border-radius: 4px;
    //     width: 20vw;
    //     top: 50px;
    //     .item {
    //       text-align: center;
    //       p {
    //         color: rgba(0, 0, 0, 0.87);
    //         margin: 0;
    //       }
    //     }
    //     .item:first-child {
    //       font-size: 16px;
    //       font-weight: bold;
    //       pointer-events: none;
    //       &:hover {
    //         background-color: initial;
    //       }
    //     }
    //   }
    //   i {
    //     display: none;
    //   }
    //   &.active {
    //     .divider.text {
    //       position: absolute;
    //     }
    //   } 
    // }
  }
}
