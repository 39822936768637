.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: $forestgreen;

  img {
    margin: 0 0 5rem;
  }

  section {
    border-radius: 4px;
    padding: 50px;
    width: 40vw;
    height: 50vh;
    max-width: 800px;
    max-height: 512px;
    .input-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .input {
        display: flex;
        flex-direction: column;
        label {
          text-transform: uppercase;
        }
      }
      input {
        margin: 1rem 0;
        padding: 0 1rem;
        width: 80%;
        font-size: 16px;
        height: 40px;
        border: solid 1px lightgray;
        border-radius: 4px;
      }
    }
    .btn-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      button {
        width: 100%;
        &:nth-of-type(2) {
          background-color: #fafafa;
        }
      }
    }
    .failed {
      color: red;
    }
  }
}
