button {
  background-color: $seafoamgreen;
  height: 50px;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-right: 15px;
  animation: all 0.4s ease-in-out;
  text-transform: capitalize;
  &:hover {
    animation: all 0.4s ease-in-out;
    cursor: pointer;
    filter: brightness(0.95);
  }
}

.MuiCalendarPicker-root, .MuiDataGrid-cell, .MuiTablePagination-actions {
  button {
    box-shadow: none;
    width: 36px;
    height: 36px;
    &.PrivatePickersYear-yearButton {
      width: auto;
    }
    &.MuiPickersDay-root.Mui-selected, &.PrivatePickersYear-yearButton.Mui-selected {
      background-color: $blue;
    }
  }
}

.MuiDataGrid-cell {
  float: right
}

