.MuiInputAdornment-root {
  button {
    width: auto;
    font-weight: 500;
    background-color: initial;
    color: initial;
    box-shadow: none;
    border: none;
    transition: all 0.1s ease-out;
    margin: 0;
    padding: 15px 5px;
    font-size: 1.25rem;
    opacity: 0.42;
    transition: all 0.1s ease-out;
    padding: 15px 5px;
    border: solid 2px transparent;
    height: 0;
    &:hover {
      transition: all 0.1s ease-out;
      background-color: $seafoamgreen;
      color: #03241C !important;
      border: solid 2px $seafoamgreen;
      opacity: 1;
    }
  }
}