html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  // font-family: "CircularXXWeb-Book" !important;
  font-family: 'Kanit', sans-serif !important;
  scroll-behavior: smooth;
}

* {
  font-family: 'Kanit', sans-serif !important;
}

// .ui.form .field {
//   width: 50%;
//   & .selection.dropdown {
//     width: 40% !important;
//   }
// }

// .ui.styled.accordion {
//   width: 100%;
//   box-shadow: 0 4px 4px 0 #00000040, 0 0 0 1px rgb(34 36 38 / 15%);
//   margin: 0 0 1rem;
//   .active {
//     padding: 2rem !important;
//   }
//   b {
//     color: $forestgreen;
//   }
//   // .title {
//   //   pointer-events: none;
//   // }
//   .title {
//     font-size: 2rem !important;
//     p {
//       font-size: 1.25rem;
//       margin: 0.5rem 0;
//       color: rgba(0, 0, 0, 0.4);
//     }
//   }
//   .content {
//     .grid {
//       div {
//         display: flex;
//         font-size: 18px;
//         color: $forestgreen;
//         flex-direction: column;
//         padding-top: 0;
//         width: 50% !important;
//         .detail-title {
//           font-size: 16px;
//           font-weight: 600;
//           text-transform: capitalize;
//           margin: 0;
//           margin-right: 7px;
//         }
//         .detail-text {
//           line-break: anywhere;
//           font-weight: 200;
//           color: #000;
//           &.related-event {
//             display: flex;
//             position: relative;
//             align-items: center;
//             cursor: pointer;
//             &:hover {
//               text-decoration: underline;
//               color: $forestgreen;
//             }
//             svg {
//               width: 1.5rem;
//               margin: 0 1rem;
//             }
//           }
//         }
//       }
//       &.subtitle {
//         .column {
//           padding-bottom: 0;
//           p {
//             font-size: 22px;
//             font-weight: bolder;
//           }
//         }
//       }
//     }
//   }
//   &:nth-of-type(1) {
//     button {
//       // width: 200px;
//       // padding-top: 2px;
//       text-transform: uppercase;
//       font-weight: 500;
//       background-color: transparent;
//       color: $blue;
//       border: solid 3px $blue;
//       transition: all 0.1s ease-out;
//       &.cancel {
//         color: gray;
//         border: solid 3px gray;
//       }
//       &.sm-btn {
//         width: 35px;
//         border-radius: 100px;
//         height: 35px;
//         margin-right: 0.5rem;
//         font-size: 12px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         background-color: $seafoamgreen;
//         color: $forestgreen;
//         border: solid 2px $seafoamgreen;
//         &.json {
//           font-size: 17px;
//         }
//         &.danger {
//           background-color: $lightRed;
//           color: $dangerRed;
//           border: solid 2px $dangerRed;
//         }
//       }
//       &:hover {
//         transition: all 0.1s ease-out;
//         filter: brightness(0.9);
//       }
//     }
//   }
// }

.btns-wrapper {
  display: flex;
}
.action-btn-wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  width: 50%;
}

.export-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  &-halfrow{
    width: 50%;
  }
}

// .export {
//   right: 0;
//   .sm-btn {
//     width: 35px;
//     border-radius: 100px;
//     height: 35px;
//     font-size: 12px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-transform: uppercase;
//   }
// }

.table-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 0;

  & button {
    padding: 5px 10px;
    height: auto;
  }
}

button.sm-btn {
  width: 35px;
  border-radius: 100px;
  height: 35px;
  margin-right: 0.5rem;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $seafoamgreen;
  color: $forestgreen;
  border: solid 2px $seafoamgreen;
  text-transform: uppercase;
  &.json {
    font-size: 17px;
  }
  &.danger {
    background-color: $lightRed;
    color: $dangerRed;
    border: solid 2px $dangerRed;
  }
  &:hover {
    transition: all 0.1s ease-out;
    filter: brightness(0.9);
  }
}

.search-result {
  display: flex;
  color: $blue;
  font-size: 14px;
  width: fit-content;
  padding: 0 1rem;
  border-radius: 100px;
  margin: 1rem 0;
  p {
    margin: 0.5rem !important;
    display: flex;
    align-items: center;
    &:nth-of-type(odd) {
      background-color: $seafoamgreen;
      box-shadow: 0px 4px 4px 0px #00000040;
      padding: 5px 30px;
      border-radius: 100px;
      color: $forestgreen;
      margin: 0;
    }
    &:last-of-type {
      color: $forestgreen;
      font-size: 16px;
      font-weight: 500;
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }
  .link {
    transition: all 0.1s ease-out;
    &:hover {
      cursor: pointer;
      transition: all 0.1s ease-out;
      filter: brightness(0.9);
    }
  }
  img {
    width: 8px;
  }
}
.filter-section {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  input,
  button {
    color: $forestgreen;
    margin: 0 2rem;
    &::placeholder {
      color: black !important;
    }
  }
  // .ui.dropdown:not(.button) > .default.text {
  //   color: black !important;
  //   opacity: 0.4;
  // }
  // .ui.fluid.dropdown .icon.clear, .ui.fluid.input {
  //   top: 20px;
  //   border: none !important;
  //   height: 30px !important;
  //   width: 30px;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 500px;
  //   background-color: transparent;
  //   transition: all 0.1s ease-out;
  //   &:before {
  //     padding-bottom: 5px;
  //     content: "+";
  //     transform: rotate(45deg);
  //     font-size: 1.75rem;
  //   }
  //   &:hover {
  //     transition: all 0.1s ease-out;
  //     background-color: $seafoamgreen;
  //     color: $forestgreen;
  //     border: solid 2px $seafoamgreen;
  //     height: 30px !important;
  //     opacity: 1 !important;
  //   }
  // }
  button {
    width: 200px;
    text-transform: uppercase;
    font-weight: 500;
    background-color: transparent;
    color: $blue;
    border: solid 3px $blue;
    transition: all 0.1s ease-out;
    &:hover {
      transition: all 0.1s ease-out;
      background-color: $seafoamgreen;
      color: $forestgreen !important;
      border: solid 2px $seafoamgreen;
      opacity: 1;
      svg {
        color: $forestgreen;
        opacity: 1 !important;
      }
    }
  }

  .MuiPaper-root {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 1rem;
    .ForwardRef-searchContainer-12 {
      margin: 0;
      width: 100%;
    }
    div {
      padding-right: 8px;
      height: 50px;
      width: auto;
    }
    button {
      width: 30px;
      height: 30px;
      color: initial;
      margin: 0 1rem;
      border: none;
      box-shadow: none;
      right: 0;
      position: absolute;
      .MuiSvgIcon-root {
        font-size: 1.25rem;
        opacity: 0.42;
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
  // .ui.fluid.dropdown, .ui.fluid.input {
  //   margin: 0 1rem;
  //   width: 15vw;
  //   min-width: 200px;
  //   border-radius: 4px;
  //   border: none;
  //   box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
  //     0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  //   height: 48px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-size: 14px;
  //   .icon {
  //     height: 100% !important;
  //     display: flex;
  //     align-items: center;
  //     margin: -0.78571429em 0 !important;
  //     opacity: 0.42 !important;
  //     transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  //   }
  //   div,
  //   i {
  //     font-size: 14px !important;
  //   }
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
  //       0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  //   }
  
  // }

  .MuiAutocomplete-root, .MuiTextField-root, .MuiInputBase-root, .MuiOutlinedInput-root {
    margin: 0 1rem;
    width: 15vw;
    min-width: 200px;
    border-radius: 4px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    .icon {
      height: 100% !important;
      display: flex;
      align-items: center;
      margin: -0.78571429em 0 !important;
      opacity: 0.42 !important;
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    }
    div,
    i {
      font-size: 14px !important;
    }
  
  }
  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  .MuiInputLabel-formControl {
    position: relative;
    color: black !important;
    opacity: 0.4;
    margin: 0 auto;
    padding: 0 2em 0 1em;
    &.MuiInputLabel-shrink {
      margin: 0 !important;
      padding-left: 30px;
    }
  }
  .MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled {
    margin: 0 15px;
    .MuiAutocomplete-popupIndicator {
      display: none;
    }
  }
  .MuiAutocomplete-inputRoot {
    height: 60px;
    margin: 0;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    &::after, &::before{
      display: none;
    }
    .MuiAutocomplete-tag {
      margin: 3px;
      top: 50px;
      position: relative;
      height: auto;
      font-size: 10px !important;
      .MuiChip-deleteIcon {
        margin: 1px 2px 1px 0;
      }
      .MuiChip-label {
        width: 100%;
      }
    }
  }
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    position: absolute;
  }
  .MuiAutocomplete-endAdornment {
    // height: 100%;
    top: 5;
    button {
      margin: 0 7px 0 0;
      width: 30px;
      height: 30px;
      border: none;
      box-shadow: none;
      color: #000000;
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    }
  }
  .MuiTextField-root {
    top: 0px;
  }
}

.MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  .MuiPaginationItem-textPrimary {
    box-shadow: none;
    &.Mui-selected {
      background-color: $algaegreen;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      &:hover {
        background-color: $algaegreen;
      }
    }
  }
  .MuiPaginationItem-sizeSmall {
    height: 30px;
    margin: 0 1px;
    padding: 0 4px;
    min-width: 30px;
    border-radius: 30px;
    font-size: 14px;
  }
  // .MuiInputBase-root {
  //   input::placeholder {
  //     color: red !important;
  //   }
  // }
}

.perpage {
  margin-bottom: 1rem;
  button {
    width: 30px;
    height: 25px;
    font-size: 12px;
    &.Mui-selected {
      background-color: $algaegreen;
      color: #ffffff;
      &:hover {
        background-color: $algaegreen;
      }
    }
  }
}

.flowchart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  figure {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 20%;
    margin: 0;
    .arrow-icon {
      position: absolute;
      pointer-events: all;
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 10rem;
      transform: translateY(0) !important;
      transition: transform 0.4s ease 0s;
      &:hover {
        cursor: pointer;
      }
      svg {
        font-size: 3rem;
        transform: translateX(0);
        transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
        &:hover {
          transform: translateY(-6px) !important;
          transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
        }
        &.warning {
          color: $forestgreen;
        }
        &.error {
          color: red;
        }
      }
    }
    .arrow {
      background-image: url("./images/ArrowShort.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      background-size: contain;
    }
    &.arrow-long-wrapper {
      position: absolute;
      left: 0;
      width: 100%;
      height: 12vh;
      max-height: 12vh;
      flex-direction: row;
      justify-content: flex-start;
      pointer-events: none;
      .arrow-long {
        background-image: url("./images/ArrowLong.svg");
        width: 80%;
        height: 50vh;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        left: 7.5%;
        position: relative;
      }
    }
  }
  .workflow-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 3rem;
    position: relative;
    width: 100%;
    .workflow-step {
      width: 20%;
      margin: 0 0 12vh;
      &:first-of-type {
        margin: 0 0 12vh;
      }
      .step-wrapper {
        width: 100%;
        display: flex;
        margin: 0;
        &.last {
          figure {
            .arrow {
              background-image: none;
            }
            .arrow-icon {
              margin: 0;
            }
          }
        }
        &.selected {
          .step {
            border: solid 3px $algaegreen;
            color: $oceangreen;
            font-weight: 500;
          }
        }
        .step {
          width: 80%;
          text-align: center;
          border: solid 3px $oceangreen;
          border-radius: 16px;
          box-shadow: 0px 4px 4px 0px #00000040;
          color: $forestgreen;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3rem 0;
          margin: 1rem 0.5rem;
          position: relative;
          p {
            position: absolute;
            width: 100%;
            font-size: 1.125rem;
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
          }
          cursor: pointer;
          transform: translateX(0);
          transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
          &:hover {
            transform: translateY(-6px) !important;
            transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
          }
          &.completed-error,
          &.transacted {
            background-color: lightgray;
            color: $forestgreen;
            pointer-events: initial;
            &.selected {
              border: solid 3px $algaegreen;
            }
          }
          &.retired {
            background-color: lightgray;
            color: $forestgreen;
            pointer-events: initial;
            &.selected {
              border: solid 3px $algaegreen;
            }
          }
          &.completed {
            background-color: $oceangreen;
            color: #ffffff;
            pointer-events: initial;
          }
          &.selected {
            border: solid 3px $algaegreen;
          }
          &.current {
            background-color: $algaegreen;
            border: solid 3px $algaegreen;
            color: #ffffff;
            font-weight: 500;
          }
          &.next{
            pointer-events: none;
          }
          &.manual{
            pointer-events: initial;
          }
        }
      }
    }
  }
}

.formModal {
  max-height: 95vh;
  overflow: hidden;
}

.formModal-content {
  overflow-y: scroll;
  max-height: calc(95vh - 180px);
}

.full-row {
  width: 100%;
}

.actionButton {
  background-color: $seafoamgreen !important;

  &:hover {
    filter: brightness(0.95) !important;
  }
}

.details-title {
  color: $forestgreen;
  font-size: 18px;
  font-weight: 500;
}

.error-label {
  color: #d32f2f;
}

.subitem {
  margin-top: 20px;
  padding-left: 20px;
}

.detail-subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: $forestgreen;
  padding: 2rem 0 1rem;
}

.clickable {
  cursor: pointer;
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

