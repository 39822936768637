.MuiIconButton-root {
    box-shadow: none;
    &:hover {
        background-color: transparent !important;
    }
}

.MuiAutocomplete-endAdornment {
    top: auto !important;
    right: 7px !important;
}