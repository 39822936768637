.MuiTabs-root {
  button {
    // pointer-events: none;
    box-shadow: none;
  }
  .Mui-selected {
    color: $forestgreen !important;
  }
  .PrivateTabIndicator-colorPrimary-2 {
    background-color: $oceangreen !important;
  }
  .jss2 {
    background-color: $oceangreen !important;
  }
}
