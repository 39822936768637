.striped-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  animation: fadeIn 0.5s;
  margin-bottom: $spacing-m;

  .empty-table {
    min-height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 100%;

    .ui.loader {
      position: inherit;
      margin-top: $spacing-m;
    }
  }

  table {
    border-collapse: collapse;
    border: none;
    margin: 0px;
    table-layout: fixed;

    th {
      border: none;
      background-color: #ffffff;
      font-weight: bold !important;
    }

    tr {
      &.clickable {
        &:hover {
          cursor: pointer;
          background-color: lightgray;
        }
      }
    }

    td {
      overflow: hidden;
      text-overflow: ellipsis;

      .ui.button {
        margin-left: auto;
        padding: 4px;
      }

      .icon {
        width: 20px;
      }
    }

    tr td {
      border-top: 0px !important;

      .label {
        display: none;
      }
    }

    tbody {
      background-color: white;
    }

    tr:nth-child(even) {
      background-color: #fafafa;
    }

    th,
    td {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .ui.pagination.menu {
    margin-left: auto;

    a.item {
      border: none;
    }
    a.active.item {
      background-color: transparent;
    }
  }

  @media screen and (max-width: 900px) {
    thead {
      display: none;
    }
    table {
      td {
        text-align: left;
        display: flex;
        flex-direction: row;

        b.label {
          display: flex;
          margin-right: $spacing-xs;
        }

        .icon {
          width: 20px;
        }
      }

      tr {
        position: relative;
      }
    }
  }
}
