$navyblue: #03241C;
$blue: #0380ff;
$greenwater: #19E8E0;
$greenwater-dark: #11C4D4;
$darkTeal:#01b1d9;
$lightBlue: #82c0ff;
$dangerRed: #C85250;
$lightRed: #F7BEC0;

$oceangreen: #214F4D;
$seafoamgreen: #7DD4B2;
$forestgreen: #03241C;
$algaegreen: #29B57D;