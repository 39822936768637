
.detail-item {
    display: inline;
    padding-bottom: 12px;
  }
  
.detail-title {
  color: $forestgreen;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  margin-right: 7px;
}

.detail-text {
  font-size: 18px;
  line-break: anywhere;
  font-weight: 200;
  color: #000;
}