.Home {
  padding: 1rem;
  .content {
    .striped-table {
      padding: 1rem 1rem 0;
      margin-bottom: 0;
      .table {
        thead,
        tbody {
          tr {
            th:nth-of-type(1),
            th:nth-of-type(2),
            th:nth-of-type(3),
            th:nth-of-type(4),
            td:nth-of-type(1),
            td:nth-of-type(2),
            td:nth-of-type(3),
            td:nth-of-type(4) {
              display: none;
            }
          }
          td {
            p {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-end;
              font-size: 14px;
              span {
                background-color: $algaegreen;
                color: white;
                border-radius: 100px;
                padding: 1px 12px;
                margin: 3px 6px 3px 0px;
              }
            }
          }
        }
      }
    }
    .ui.loader {
      position: relative;
    }
    .empty-table {
      height: 25vh;
    }
  }

  .filter-section {
    margin: 0 0 2rem;
    // .ui.fluid.dropdown,
    .MuiPaper-root {
      // width: 211px;
      margin: 0 0.5rem;
      // text-align: center;
      div {
        width: 100%;
        input {
          margin: 0;
          // text-align: center;
        }
      }
    }
    // .ui.fluid.dropdown.active {
    //   padding: 0 !important;
    // }
    input {
      margin: 0;
      padding: 5px 15px;
    }

    button {
      margin: 0 8px;
      &:last-of-type {
        margin: 0;
      }
    }

    .MuiInputAdornment-root {
      button {
        width: auto;
        font-weight: 500;
        background-color: initial;
        color: initial;
        box-shadow: none;
        border: none;
        transition: all 0.1s ease-out;
        margin: 0;
        padding: 15px 5px;
        font-size: 1.25rem;
        opacity: 0.42;
        transition: all 0.1s ease-out;
        padding: 15px 5px;
        border: solid 2px transparent;
        height: 0;
        &:hover {
          transition: all 0.1s ease-out;
          background-color: $seafoamgreen;
          color: $forestgreen !important;
          border: solid 2px $seafoamgreen;
          opacity: 1;
        }
      }
    }

    .MuiOutlinedInput-root {
      border: none;
      height: 48px;
      &:focus-visible {
        outline: none;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }

    .MuiTextField-root {
      // width: 211px;
      // top: 0px;
      margin: 0 0.5rem;
      label {
        position: absolute;
        padding: 0 5px;
        margin: initial;
        // top: -2px;
        text-align: center;
        transition: all 0.2s ease-out;
        &.MuiInputLabel-shrink {
          width: initial;
          padding: 0;
          transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          top: 0;
        }
      }
    }

    .datepicker-wrapper {
      p {
        position: absolute;
        margin: 0.25rem 0.5rem;
        color: red;
        font-size: .75rem;
      }
    }
  }
  
}

.loading-page {
  transition: all 2s ease-in-out;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: $forestgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90px;
    height: 90px;
  }
}
