.Home {
  padding: 1rem;
  .content {
    .striped-table {
      padding: 1rem 1rem 0;
      margin-bottom: 0;
      .table {
        thead,
        tbody {
          tr {
            th:nth-of-type(1),
            th:nth-of-type(2),
            th:nth-of-type(3),
            th:nth-of-type(4),
            td:nth-of-type(1),
            td:nth-of-type(2),
            td:nth-of-type(3),
            td:nth-of-type(4) {
              display: none;
            }
          }
          td {
            p {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-end;
              font-size: 14px;
              span {
                background-color: $algaegreen;
                color: white;
                border-radius: 100px;
                padding: 1px 12px;
                margin: 3px 6px 3px 0px;
              }
            }
          }
        }
      }
    }
    .ui.loader {
      position: relative;
    }
    .empty-table {
      height: 25vh;
    }
  }

  .filter-section {
    // .ui.fluid.dropdown,
    .MuiPaper-root {
      width: 15vw;
      // text-align: center;
      div:first-of-type {
        width: 100%;
        margin: 0;
      }
    }
    button {
      button {
        width: auto;
        margin: 0 2px 0 0;
      }
    }
  }
}

.loading-page {
  transition: all 2s ease-in-out;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: $forestgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90px;
    height: 90px;
  }
}
