:root {
  --spacing-gap: 2px;
  --spacing-xs: 6px;
  --spacing-s: 12px;
  --spacing-m: 24px;
  --spacing-l: 32px;
  --spacing-xl: 48px;

  --input-height: 40px;
  --max-page-width: 1000px;
}

$spacing-gap: #{var(--spacing-gap)};
$spacing-xs: #{var(--spacing-xs)};
$spacing-s: #{var(--spacing-s)};
$spacing-m: #{var(--spacing-m)};
$spacing-l: #{var(--spacing-l)};
$spacing-xl: #{var(--spacing-xl)};

$input-height: #{var(--input-height)};
$max-page-width: #{var(--max-page-width)};
