.details {
  width: 100%;
  min-height: 100vh;
  padding: 1rem 1rem 10vh;
  
  .top-content {
    display: flex;
    justify-content: space-between;
    .subtitles {
      display: flex;
      flex-direction: column;
      div {
        margin: 0 0 0.25rem;
        display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: flex-end;
        position: relative;
        p {
          background-color: dimgray;
          color: #ffffff;
          font-size: 0.875rem;
          font-weight: lighter;
          padding: 1px 10px;
          border-radius: 6px;
          margin: 0 4px;
        }
        svg {
          font-size: 2rem;
          &.warning {
            color: $forestgreen;
          }
          &.error {
            color: red;
          }
        }
      }
    }
  }
  .bottom-content {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #ffa500;
    margin: 0 0 1rem;
    svg {
      font-size: 2.5rem;
      margin: 0px 7px;
    }
    p {
      font-size: 18px;
    }
  }
  .content {
    .grid {
      .message {
        width: 75% !important;
      }
    }
    .detail-subtitle {
      font-size: 1.5rem;
      font-weight: bold;
      color: $forestgreen;
      padding: 2rem 0 1rem;
    }
  }
  
  .content {
    padding: 2rem !important;
    .flowchart {
      .eventslist-wrapper {
        display: none;
        pointer-events: all;
        position: relative;
        width: 100%;
        top: -50px;
        left: -7px;
        &:before {
          content: " ";
          position: absolute;
          width: 0;
          height: 0;
          left: 48%;
          bottom: -6px;
          border: 7px solid;
          border-color: grey transparent transparent grey;
          transform: rotate(225deg);
        }
        &:after {
          content: " ";
          position: absolute;
          width: 0;
          height: 0;
          left: 50%;
          bottom: -4.5px;
          border: 6px solid;
          border-color: #fff transparent transparent #fff;
          transform: rotate(225deg);
        }
        &.clicked {
          display: flex;
        }
        &.both-icons {
          left: -26px;
        }
      }
      .eventslist {
        width: 33vw;
        position: absolute;
        height: auto;
        max-height: 20vh;
        bottom: 104%;
        overflow-y: auto;
        background-color: white;
        border-radius: 8px;
        border: 1px solid grey;
        table {
          border-radius: 0;
          border: none;
          tbody {
            tr {
              cursor: pointer;
              &:hover {
                background-color: lightgray !important;
              }
              td {
                font-size: 0.866rem;
                width: auto;
                overflow-wrap: anywhere;
              }
            }
          }
          thead {
            tr {
              th {
                padding: 0.78571429em 8.25px;
              }
            }
          }
        }
        &.left {
          right: -50%;
        }
      }
      .workflow-wrapper .workflow-step .step-wrapper {
        pointer-events: none;
        &.eventlist-to-left,
        &.last {
          .eventslist-wrapper {
            left: -28rem;
            &:after {
              left: 29.35rem;
            }
            &:before {
              left: 29.26rem;
            }
            &.both-icons {
              left: -29.5rem;
            }
          }
        }
        &.last {
          .eventslist-wrapper {
            top: 1.5rem;
          }
        }
      }
      .workflow-wrapper .workflow-step .step-wrapper.completed .step {
        color: #ffffff;
        pointer-events: initial;
        font-weight: 400;
      }
      .workflow-wrapper .workflow-step .step-wrapper.current .step {
        color: $forestgreen;
        font-weight: 500;
      }
    }
  }
}
